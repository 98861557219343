import { MessageDateTime } from '@/features/MessagesList';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ChatInfo } from '../ChatInfo.styled';
import { ChatItemWrapper } from '../ChatItemWrapper.styled';
import { ChatLastMessageDateTime } from '../ChatLastMessageDateTime.styled';
import { ChatLastMessageExcerptSwitcher } from '../MessageExcerptTypes';
import { ChatItemProps } from './ChatItem';

type ChatItemIrProps = Omit<ChatItemProps, 'tags'>;

export function ChatItemIr({ chat, selected, tabIndex, onClick }: ChatItemIrProps) {
  const unreadChat = chat.status === 'unread';

  return (
    <ChatItemWrapper
      tabIndex={tabIndex}
      selected={selected}
      status={chat.status}
      data-testid={chat.contact.id}
      direction="column"
      onClick={onClick}
      accountType="ir"
    >
      <Flex direction="row" align="center" className="contact-basic-info">
        <ChatInfo as="span" fontSize="bodyLargeStandard" unread={unreadChat} selected={selected}>
          <span className="chat-contact-name">{chat.contact.name.toLowerCase()} </span>
          <FloraTypography as="span" fontSize="bodySmallStandard" css={{ fontVariantNumeric: 'tabular-nums' }}>
            ({chat.contact.vdId})
          </FloraTypography>
        </ChatInfo>
        <ChatLastMessageDateTime>
          <MessageDateTime createdAt={chat.message.createdAt} />
        </ChatLastMessageDateTime>
      </Flex>
      <Flex
        css={{
          marginTop: '$2',
          paddingLeft: '0',
          color: '#444',
          fontSize: '$auxiliar',
        }}
        align="center"
        direction="row"
        className="more-info"
      >
        <ChatLastMessageExcerptSwitcher message={chat.message} />
      </Flex>
    </ChatItemWrapper>
  );
}
