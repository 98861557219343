import { KeyboardEvent, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface MessageSenderFormSubmitProps {
  isRequired?: boolean;
  ref: React.RefObject<HTMLInputElement>;
  registerName: string;
  onFormSubmit: (formValue: any) => void;
}

export const useMessageSenderFormSubmit = ({
  isRequired = false,
  ref,
  registerName,
  onFormSubmit,
}: MessageSenderFormSubmitProps) => {
  const { register, handleSubmit, reset, setValue, watch } = useForm({
    reValidateMode: 'onSubmit',
  });

  const currentValue: string | undefined = watch(registerName);

  const onSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleSubmit((formValue: any) => {
      onFormSubmit(formValue);
      reset();
      if (ref.current) {
        ref.current.innerText = '';
      }
    })();
  };

  const handleOnInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(registerName, event.currentTarget.innerText, {
      shouldValidate: true,
    });
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  const resetTextMessage = useCallback(() => {
    setValue(registerName, undefined, {
      shouldValidate: true,
    });
  }, [registerName, setValue]);

  useEffect(() => {
    register(registerName, { required: isRequired });
  }, [register]);

  return { handleInputKeyDown, onSubmit, handleOnInput, currentValue, setValue, resetTextMessage };
};
