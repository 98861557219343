import { DEFAULT_LOCALE } from '../locale';

export const toCurrency = (
  value: number = 0,
  locale: LocaleOption = { currency: 'BRL', loc: DEFAULT_LOCALE },
): string => {
  return value.toLocaleString(locale.loc, {
    style: 'currency',
    currency: locale.currency,
  });
};

interface LocaleOption {
  loc: string;
  currency: string;
}
