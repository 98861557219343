import { memo } from 'react';
import { ChatBubbleOutlineIcon } from '@grupoboticario/flora-react-icons';
import { Text } from '../Text';
import { TemplateName } from './TemplateName';

import { useFetchTemplate } from './useFetchTemplate';
import { TemplateComponentTypesSwitcher } from './TemplateComponentTypesSwitcher';
import { Message } from '@/shared/services/api/instant-messenger/types/InstantMessengerType';
import { useAccountStore } from '@/shared/state';

type WhatsAppTemplateMessageProps = {
  rawTemplate: Extract<Message, { type: 'rawTemplate' }>['rawTemplate'];
};

export const WhatsAppTemplate = memo(({ rawTemplate }: WhatsAppTemplateMessageProps) => {
  const { account } = useAccountStore();
  const { name, components: variables } = rawTemplate;
  const { data: components, isLoading, error } = useFetchTemplate({ templateName: name, accountId: account.id });

  if (isLoading) {
    return <Text text="Carregando..." isAMessageFromSelectedContact={false} />;
  }

  if (error) {
    return <Text text="Não foi possível carregar o template neste momento" isAMessageFromSelectedContact={false} />;
  }

  return (
    <>
      <TemplateName fontSize="bodySmallStandard" fontWeight="bold">
        <ChatBubbleOutlineIcon css={{ verticalAlign: 'middle' }} /> Template: {name}
      </TemplateName>

      <TemplateComponentTypesSwitcher templateComponents={components} templateParams={variables} />
    </>
  );
});
