import { FC } from 'react';
import { styled, Flex, FlexProps } from '@grupoboticario/flora-react';

export const MessageReactionsListWrapper = styled(Flex, {
  position: 'absolute',
  bottom: '-12px',
  gap: '4px',
  minHeight: '26px',
  padding: '0 6px',
  borderRadius: '$large',
  backgroundColor: 'white',
  boxShadow: '$downClose',
  border: '1px solid rgba(0, 0, 0, .05)',
  cursor: 'default',
  variants: {
    isAMessageFromSelectedContact: {
      true: {
        left: '4px',
      },
      false: {
        right: '4px',
      },
    },
  },
}) as FC<FlexProps & { isAMessageFromSelectedContact: boolean }>;
