import { MessageType } from '@/shared/components/Notifications';

export const GENERIC_ERROR: MessageType = {
  kind: 'error',
  description: 'Desculpe, algo deu errado.',
};

export const GERA_ID_NOT_FOUND: MessageType = {
  kind: 'error',
  description: 'Não foi possível encontrar uma conta associada ao [e-mail/código Gera]. Por favor, tente novamente.',
};
