import { styled, Box } from '@grupoboticario/flora-react';

export const MessageSenderWrapper = styled(Box, {
  padding: '$2',
  position: 'relative',
  variants: {
    isChatSessionOpen: {
      true: {
        backgroundColor: '$nonInteractiveEmphasis',
      },
      false: {
        backgroundColor: '$backgroundSecondary',
      },
    },
  },
});
