import { memo, useEffect, useState } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { getMediaUrl } from '@/shared/services/api/instant-messenger/service/getMedia';
import { useAccountStore } from '@/shared/state';

type AudioIdMessageProps = { id: string };
type AudioLinkMessageProps = { link: string };
type AudioMessageProps = AudioIdMessageProps | AudioLinkMessageProps;

export const Audio = memo((props: AudioMessageProps) => {
  const [src, setSrc] = useState<string | undefined>(() => ('link' in props ? props.link : undefined));
  const prevId = usePrevious((props as AudioIdMessageProps).id);
  const { account } = useAccountStore();

  async function fetchAudio(mediaId: string) {
    const res = await getMediaUrl({ accountId: account.id, mediaId });
    setSrc(res.url);
  }

  useEffect(
    function fetchAudioWhenNoLinkProvided() {
      if (!src) {
        fetchAudio((props as AudioIdMessageProps).id);
      }
    },
    [src],
  );

  useEffect(
    function fetchAudioWhenIdChanges() {
      if ((props as AudioIdMessageProps).id !== prevId && prevId !== undefined) {
        fetchAudio((props as AudioIdMessageProps).id);
      }
    },
    [prevId, (props as AudioIdMessageProps).id],
  );

  if (src) {
    return <audio src={src} controls autoPlay={false} />;
  }

  return null;
});
