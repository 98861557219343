import { format, parseISO } from 'date-fns';

import { MessageReactionsListWrapper } from './MessageReactionsListWrapper.styled';

import { Reaction } from '@/shared/services/api/instant-messenger/types';

export interface MessageReactionListProps {
  reactions?: Array<Reaction>;
  isAMessageFromSelectedContact: boolean;
}

export function MessageReactionsList({ reactions, isAMessageFromSelectedContact }: MessageReactionListProps) {
  if (!reactions || reactions.every(reaction => !reaction.emoji)) {
    return null;
  }

  return (
    <MessageReactionsListWrapper
      align="center"
      justify="center"
      isAMessageFromSelectedContact={isAMessageFromSelectedContact}
    >
      {reactions.map(reaction => (
        <span
          key={`${reaction.from}-${reaction.emoji}`}
          title={format(parseISO(reaction.date), "dd/MM/yyyy 'às' HH:mm")}
          style={{ display: 'inline-block' }}
        >
          {reaction.emoji}
        </span>
      ))}
    </MessageReactionsListWrapper>
  );
}
