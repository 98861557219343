export function formatText(text: string): string {
  return (
    text
      // Substitui quebras de linha no início e no final do texto por uma única quebra de linha
      .replace(/(?:^\r\n|\r\n$)/gm, '\n')
      .replace(/(?:^\n\n|\n\n$)/gm, '')
      // Escapa caracteres HTML especiais
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      // Converte URLs em links clicáveis
      .replace(/(?<!\w)(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
      // Formata blocos de código
      .replace(/```(.*?)```/gs, (_match, code) => `<code>${code}</code>`)
      // Formata texto em negrito
      .replace(/(?<!\w)\*(.+?)\*(?!\w)/g, (_match, p1) => `<strong>${p1}</strong>`)
      // Formata texto em itálico
      .replace(/(?<!\w)_(.+?)_(?!\w)/g, (_match, p1) => `<em>${p1}</em>`)
      // Formata texto tachado (riscado)
      .replace(/(?<!\w)~(.+?)~(?!\w)/g, (_match, p1) => `<del>${p1}</del>`)
  );
}
