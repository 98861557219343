import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { events } from '@/shared/services/events';
import { Box } from '@grupoboticario/flora-react';
import { useState, ChangeEvent, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { validateFile, createFileValidationSchema } from '../../validations';
import { ButtonClose } from './ButtonClose';
import { ButtonSend } from './ButtonSend';
import { ThumbnailList } from './ThumbnailList';
import { Selected } from './Selected';
import type { ImageItem } from './types';
import { useAccountStore } from '@/shared/state';

type ImagePreviewerProps = {
  isUploadingMedia: boolean;
  sendMessageWithReply: (payload: SendMessageData) => Promise<void>;
  setIsUploadingMedia: (isUploading: boolean) => void;
  setMediaUploaderMenuVisibility: (isOpen: boolean) => void;
};

const IMAGE_MAX_FILE_SIZE = 5;
const ALLOWED_IMAGE_MIME_TYPE = ['image/png', 'image/jpeg'];

export function ImagePreviewer({
  isUploadingMedia,
  sendMessageWithReply,
  setIsUploadingMedia,
  setMediaUploaderMenuVisibility,
}: ImagePreviewerProps) {
  const [renderCount, setRenderCount] = useState(0);
  const [imagePreviewList, setImagePreviewList] = useState<Array<ImageItem>>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { account } = useAccountStore();

  function validateImageFiles(e: ChangeEvent<HTMLInputElement>) {
    setRenderCount(renderCount => renderCount + 1);
    if (!e.target?.files?.length) {
      return;
    }

    // Cria uma lista de arquivos a partir do evento
    const fileList = [...e.target.files];

    /*
     * Validar cada arquivo com base no schema de validação
     * O schema valida por tamanho e por tipo de arquivo
     * Se algum arquivo está fora schema, cancele o envio e exiba um erro
     */
    for (const file of fileList) {
      const validationResult = validateFile(
        file,
        createFileValidationSchema({
          allowedMimeTypeList: ALLOWED_IMAGE_MIME_TYPE,
          maxFileSizeInMB: IMAGE_MAX_FILE_SIZE,
        }),
      );
      if (!validationResult.isValid) {
        events.error({ description: validationResult.error });
      }

      setImagePreviewList(prev => {
        if (validationResult.isValid) {
          return [
            ...prev,
            {
              url: URL.createObjectURL(file),
              name: file.name,
              size: file.size,
              rawFile: file,
            },
          ];
        }
        return prev;
      });
    }
  }

  const removeImagePreviewFromList = useCallback((name: string) => {
    setImagePreviewList(prevPreviews => {
      const updatedPreviews = prevPreviews.filter(preview => preview.name !== name);
      setSelectedImageIndex(0);
      return updatedPreviews;
    });
  }, []);

  const uploadImages = useCallback(async () => {
    try {
      setIsUploadingMedia(true);

      // Envia cada arquivo da lista de acordo com a seleção do usuário
      for await (const file of imagePreviewList) {
        try {
          const { id } = await uploadMedia({ accountId: account.id, file: file.rawFile });
          await sendMessageWithReply({
            provider: 'whatsapp',
            type: 'image',
            image: {
              id,
            },
          });
          events.emit('NOTIFICATION', {
            description: `Imagem ${file.name} enviada com sucesso`,
            kind: 'success',
          });
        } catch (error) {
          events.error({ description: `Erro ao enviar a imagem ${file.name}, tente novamente.` });
        }
      }
    } catch (error) {
      events.error({ description: 'Erro ao enviar mensagens do tipo imagem, tente novamente.' });
    } finally {
      setImagePreviewList([]);
      setIsUploadingMedia(false);
    }
  }, [imagePreviewList, sendMessageWithReply, setIsUploadingMedia, setImagePreviewList]);

  return (
    <>
      {imagePreviewList.length > 0 &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              bottom: '0',
              left: '0',
              zIndex: 100,
              maxHeight: '100vh',
            }}
          >
            <ButtonClose setImagePreviewList={setImagePreviewList} isUploadingMedia={isUploadingMedia} />
            <Box
              css={{
                position: 'absolute',
                background: '#e9edef',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <Selected imagePreviewList={imagePreviewList} selectedImageIndex={selectedImageIndex} />
              <Box
                css={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  margin: '0 1rem',
                  gap: '1rem',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ThumbnailList
                  imagePreviewList={imagePreviewList}
                  removeImagePreviewFromList={removeImagePreviewFromList}
                  selectedImageIndex={selectedImageIndex}
                  setSelectedImageIndex={setSelectedImageIndex}
                  isUploadingMedia={isUploadingMedia}
                />
                <ButtonSend
                  imagePreviewListLength={imagePreviewList.length}
                  isUploadingMedia={isUploadingMedia}
                  uploadImages={uploadImages}
                />
              </Box>
            </Box>
          </div>,
          document.querySelector('#uploader-previewer')!,
        )}
      <input
        multiple
        key={renderCount}
        hidden
        type="file"
        id="image-input-uploader"
        accept={ALLOWED_IMAGE_MIME_TYPE.join(',')}
        onChange={validateImageFiles}
        onClick={() => {
          setMediaUploaderMenuVisibility(false);
        }}
      />
    </>
  );
}
