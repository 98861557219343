import { Box } from '@grupoboticario/flora-react';

export function MediaUploadProgress() {
  return (
    <Box
      id="media-upload-progress"
      css={{
        width: '100%',
        position: 'absolute',
        top: '-8px',
        zIndex: '10',
        left: '0',
        height: '8px',
      }}
    />
  );
}
