import { ChangeEvent, useState } from 'react';
import { DocumentSingleIcon } from '@grupoboticario/flora-react-icons';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { events } from '@/shared/services/events';
import { createFileValidationSchema, validateFile } from '../validations';
import MediaUploaderButton from './MediaUploaderButton';
import { useAccountStore } from '@/shared/state';

type DocumentUploaderButtonProps = {
  sendMessageWithReply: (payload: SendMessageData) => Promise<void>;
  setIsUploadingMedia: (isUploading: boolean) => void;
};

const DOCUMENT_MAX_FILE_SIZE = 100;
const ALLOWED_DOCUMENT_MIME_TYPE = [
  'text/plain',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export function DocumentUploaderButton({ sendMessageWithReply, setIsUploadingMedia }: DocumentUploaderButtonProps) {
  const [renderCount, setRenderCount] = useState(0);
  const { account } = useAccountStore();

  async function uploadDocument(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files?.length) {
      return;
    }

    const file = e.target.files[0];

    const validationResult = validateFile(
      file,
      createFileValidationSchema({
        allowedMimeTypeList: ALLOWED_DOCUMENT_MIME_TYPE,
        maxFileSizeInMB: DOCUMENT_MAX_FILE_SIZE,
      }),
    );
    if (!validationResult.isValid) {
      events.error({ description: validationResult.error });
      return;
    }

    try {
      setIsUploadingMedia(true);
      const { id } = await uploadMedia({ accountId: account.id, file });
      await sendMessageWithReply({
        provider: 'whatsapp',
        type: 'document',
        document: {
          id,
          filename: file.name,
        },
      });
      events.emit('NOTIFICATION', {
        description: `Documento ${file.name} enviado com sucesso`,
        kind: 'success',
      });
    } catch (error) {
      return events.error({ description: 'Erro ao enviar mensagem do tipo documento, tente novamente.' });
    } finally {
      setIsUploadingMedia(false);
      setRenderCount(renderCount => renderCount + 1);
    }
  }

  return (
    <>
      <MediaUploaderButton
        IconComponent={<DocumentSingleIcon />}
        htmlFor="document-input-uploader"
        ariaLabel="Clique para selecionar um arquivo de documento para enviar"
      />

      <input
        key={renderCount}
        hidden
        type="file"
        id="document-input-uploader"
        accept={ALLOWED_DOCUMENT_MIME_TYPE.join(',')}
        onChange={uploadDocument}
      />
    </>
  );
}
