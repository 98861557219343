import { fetcher } from '@/shared/services/api/fetcher';
import { Reseller } from '@/shared/types/interfaces';

export async function getResellerData({
  vdId,
  signal,
}: {
  vdId: Reseller['vdId'];
  signal?: AbortSignal;
}): Promise<Reseller> {
  return fetcher.get<Reseller>(`v1/contacts/${vdId}`, { signal });
}
