import { styled } from '@grupoboticario/flora-react';
import { FC, ReactNode } from 'react';

export const ActivityCycleTag = styled('span', {
  width: '24px',
  marginRight: '$2',
  padding: '$1',
  color: '#000',
  border: '1px',
  borderStyle: 'solid',
  borderColor: '#ccc',
  borderLeftWidth: '3px',
  backgroundColor: 'rgb(238, 238, 238)',
  fontVariant: 'tabular-nums',
  fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important',
  fontSize: '11px !important',
  borderRadius: '4px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    currentCycle: {
      A0: { borderLeftColor: '#ccc' },
      A1: { borderLeftColor: '$assorted6' },
      A2: { borderLeftColor: '$assorted5' },
      A3: { borderLeftColor: '$assorted4' },
      I4: { borderLeftColor: '$assorted3' },
      I5: { borderLeftColor: '$assorted2' },
      I6: { borderLeftColor: '$assorted1' },
      I7: { borderLeftColor: '#444' },
      null: {
        border: '1px solid #ccc',
      },
      '': {
        border: '1px solid #ccc',
      },
    },
  },
}) as FC<{ children: ReactNode; currentCycle: string | null }>;
