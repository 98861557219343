import { Grid } from '@grupoboticario/flora-react';
import { EditableInput } from '../EditableInput';
import { MessageSenderButton } from '../MessageSenderButton';

interface FormMessageSenderProps {
  currentValue?: string;
  formSubmit: (formValues: any) => void;
  handleInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  messageSenderRef: React.RefObject<HTMLInputElement>;
  handlePaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const FormMessageSender = ({
  formSubmit,
  handleInputKeyDown,
  handleOnInput,
  messageSenderRef,
  handlePaste,
}: FormMessageSenderProps) => {
  return (
    <Grid
      as="form"
      align="flex-end"
      data-testid="send-message-form"
      onSubmit={formSubmit}
      templateColumns="1fr auto"
      css={{ width: '100%' }}
      onPaste={handlePaste}
    >
      <EditableInput ref={messageSenderRef} onKeyDown={handleInputKeyDown} onInput={handleOnInput} />

      <MessageSenderButton />
    </Grid>
  );
};
