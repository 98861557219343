import { Text } from '../Text';
import { Image } from '../Image/Image';
import { Video } from '../Video/Video';
import { Document } from '../Document/Document';

import { TemplateHeaderComponent, TemplateHeaderParams } from '@/shared/services/api/instant-messenger/types';
import { mergeParamsWithinTemplate } from './TemplateComponentTypesSwitcher';

export function TemplateHeader({
  component,
  paramsList,
}: {
  component: TemplateHeaderComponent;
  paramsList: TemplateHeaderParams['parameters'] | undefined;
}) {
  if (component.format === 'TEXT') {
    return (
      <Text
        key={component.text}
        text={mergeParamsWithinTemplate({ params: paramsList, templatePlaceholder: component.text })}
        isAMessageFromSelectedContact={false}
      />
    );
  }

  const singleParam = paramsList?.[0];
  const singleParamType = singleParam?.type;

  if (component.format === 'IMAGE' && singleParamType === 'image') {
    return <Image key={singleParam.image.id} id={singleParam.image.id} />;
  }

  if (component.format === 'VIDEO' && singleParamType === 'video') {
    return <Video key={singleParam.video.id} id={singleParam.video.id} />;
  }

  if (component.format === 'DOCUMENT' && singleParamType === 'document') {
    return (
      <Document
        key={singleParam.document.id}
        id={singleParam.document.id}
        isAMessageFromSelectedContact={false}
        filename="Documento enviado com o template"
      />
    );
  }
}
