import { FloraButton } from '@grupoboticario/flora-react';
import { PaperAirplaneIcon } from '@grupoboticario/flora-react-icons';

type ButtonSendProps = {
  imagePreviewListLength: number;
  isUploadingMedia: boolean;
  uploadImages: () => void;
};

export function ButtonSend({ isUploadingMedia, uploadImages, imagePreviewListLength }: ButtonSendProps) {
  return (
    <FloraButton
      isLoading={isUploadingMedia}
      disabled={isUploadingMedia}
      onClick={uploadImages}
      data-image-count={imagePreviewListLength}
      size="large"
      has="iconOnly"
      icon={<PaperAirplaneIcon />}
      css={{
        borderRadius: '100%',
        position: 'relative',
        '&::before': {
          content: 'attr(data-image-count)',
          position: 'absolute',
          top: '-4px',
          right: '-4px',
          color: 'black',
          fontVariantNumeric: 'tabular-nums',
          backgroundColor: 'white',
          borderRadius: '100%',
          padding: '4px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '$downClose',
          fontSize: '14px',
          minWidth: '24px',
          minHeight: '24px',
        },
      }}
    />
  );
}
