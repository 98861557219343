import { httpClientCAInstance } from '@/shared/services/api';
import { Credits, Reseller } from '@/shared/types/interfaces';

type GetResellerCreditsResponse = Credits;

export async function getResellerCredits({
  vdId,
  signal,
}: {
  vdId: Reseller['vdId'];
  signal: AbortSignal;
}): Promise<GetResellerCreditsResponse> {
  const res = await httpClientCAInstance(`resellers/${vdId}/credits`, {
    method: 'GET',
    signal,
  });

  return res.json() as Promise<GetResellerCreditsResponse>;
}
