import { Flex, CSS } from '@grupoboticario/flora-react';
import { ClockIcon, CheckSingleIcon, CheckDoubleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons';

import { MessageStatusType } from '@/shared/services/api/instant-messenger/types';

export function MessageStatusIcon({ status, size, css }: { status: MessageStatusType; size: number; css: CSS }) {
  switch (status) {
    case 'stored':
      return (
        <Flex title="Armazenado">
          <ClockIcon color="$backgroundPrimary" size={size} css={css} />
        </Flex>
      );
    case 'sent':
      return (
        <Flex title="Enviado">
          <CheckSingleIcon color="$backgroundPrimary" size={size} css={css} />
        </Flex>
      );
    case 'delivered':
      return (
        <Flex title="Entregue">
          <CheckDoubleIcon color="$backgroundPrimary" size={size} css={css} />
        </Flex>
      );
    case 'read':
      return (
        <Flex title="Lido">
          <CheckDoubleIcon size={size} css={{ ...css, color: '#34B7F1' }} />
        </Flex>
      );
    case 'failed':
      return (
        <Flex title="Não entregue">
          <CrossCircleIcon color="$backgroundPrimary" size={size} css={css} />
        </Flex>
      );
    default:
      return null;
  }
}
