import { Grid } from '@grupoboticario/flora-react';

import { BlankState } from './components/BlankState';
import { MessagesContainer } from './components/MessagesContainer';

import { MessageSender } from '@/features/MessageSender';
import { ContactInfo } from '@/features/ContactInfo';

import { events } from '@/shared/services/events';
import { useEffect, useState } from 'react';
import { MessageReceived } from '@/shared/services/api/instant-messenger/types';
import { useContactStore } from '@/shared/state';

export function MessagesList() {
  const [isChatSessionOpen, setIsChatSessionOpen] = useState(false);
  const { contact } = useContactStore();

  useEffect(() => {
    if (contact?.whatsAppSessionExp) {
      return setIsChatSessionOpen(new Date(contact.whatsAppSessionExp).getTime() > Date.now());
    }
    setIsChatSessionOpen(false);
  }, [contact]);

  useEffect(() => {
    function messageReceivedHander(event: MessageReceived) {
      if (event.contact.id === contact?.id) {
        setIsChatSessionOpen(true);
      }
    }

    events.on('MESSAGE_RECEIVED', messageReceivedHander);
    return () => {
      events.off('MESSAGE_RECEIVED', messageReceivedHander);
    };
  }, [contact?.id]);

  if (!contact) {
    return <BlankState />;
  }

  return (
    <Grid key={contact.id} templateRows="auto 1fr auto" css={{ height: '100dvh', position: 'relative' }}>
      <ContactInfo />
      <MessagesContainer isChatSessionOpen={isChatSessionOpen} setIsChatSessionOpen={setIsChatSessionOpen} />
      <MessageSender isChatSessionOpen={isChatSessionOpen} />
      <div id="uploader-previewer" />
    </Grid>
  );
}
