import { FloraTypography } from '@grupoboticario/flora-react';
import DOMPurify from 'dompurify';
import { formatText } from '@/shared/functions/formatText';

export function Text({ text }: { text: string }) {
  const textWithoutAttendantName = text.replace(/^\*.+\*:\n/gim, '');
  const cleanText = DOMPurify.sanitize(formatText(textWithoutAttendantName));

  return (
    <FloraTypography
      fontSize="exceptionsAuxiliar"
      as="span"
      css={{
        width: '380px',
        paddingRight: '24px',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      dangerouslySetInnerHTML={{ __html: cleanText }}
    ></FloraTypography>
  );
}
