import { useQuery } from '@tanstack/react-query';
import { findTemplate } from '@/shared/services/api/instant-messenger/service';

export function useFetchTemplate({ templateName, accountId }: { templateName: string; accountId: string }) {
  return useQuery({
    queryKey: ['template', accountId, templateName],
    queryFn: ({ signal }) => findTemplate({ accountId, templateName, signal }),
    select(data) {
      return data.components;
    },
    gcTime: 12 * 60 * 60 * 1000, // 12 hours
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
}
