import { httpClientCIInstance } from '@/shared/services/api';
import { IrCredits } from '@/shared/types/interfaces';

type GetIrCreditsResponse = IrCredits;

export async function getIrCredits({
  vdId,
  signal,
}: {
  vdId: string;
  signal: AbortSignal;
}): Promise<GetIrCreditsResponse> {
  const res = await httpClientCIInstance(`v1/resellers/${vdId}/creditosrevendedora`, {
    method: 'GET',
    signal,
  });

  return res.json() as Promise<GetIrCreditsResponse>;
}
