import { useQuery } from '@tanstack/react-query';
import { getTemplates } from '../services/api/instant-messenger/service';
import { Account } from '../types/interfaces';

export function useFetchTemplates({ accountId }: { accountId: Account['id'] }) {
  return useQuery({
    queryKey: [accountId, 'templates'],
    queryFn: ({ signal }) => getTemplates({ accountId, signal }),
    select(data) {
      return data.data;
    },
    gcTime: 12 * 60 * 60 * 1000, // 12 hours
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
}
