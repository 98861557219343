import { memo, ReactNode } from 'react';
import { Flex } from '@grupoboticario/flora-react';

import { MessageDateTime } from '../MessageDateTime';
import { MessageReactionsList } from '../MessageReactionsList/';

import { MessageStatusType, Reaction } from '@/shared/services/api/instant-messenger/types';
import { MessageWrapperStyled } from './MessageWrapper.styled';
import { MessageStatusIcon } from './MessageStatusIcon';

interface MessageWrapperProps {
  createdAt: string;
  reactions?: Array<Reaction>;
  status: MessageStatusType;
  children: ReactNode;
  isAMessageFromSelectedContact: boolean;
}

export const MessageWrapper = memo(
  ({ createdAt, status, reactions, children, isAMessageFromSelectedContact }: MessageWrapperProps): JSX.Element => {
    return (
      <MessageWrapperStyled isAMessageFromSelectedContact={isAMessageFromSelectedContact} direction="column">
        <Flex direction="column" wrap="wrap" gapY="$2">
          {children}
          <Flex direction="row" wrap="wrap" align="center" justify="end">
            <MessageDateTime createdAt={createdAt} />
            {!isAMessageFromSelectedContact && (
              <MessageStatusIcon status={status} size={16} css={{ marginLeft: '$1' }} />
            )}
          </Flex>
        </Flex>

        <MessageReactionsList reactions={reactions} isAMessageFromSelectedContact={isAMessageFromSelectedContact} />
      </MessageWrapperStyled>
    );
  },
);
