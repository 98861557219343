import { ChatMessage } from '@/shared/types/interfaces';
import { Audio } from './Audio';
import { Button } from './Button';
import { Document } from './Document';
import { Image } from './Image';
import { RawTemplate } from './RawTemplate';
import { Reaction } from './Reaction';
import { ServiceRequest } from './ServiceRequest';
import { Sticker } from './Sticker';
import { Text } from './Text';
import { Video } from './Video';

export function ChatLastMessageExcerptSwitcher({ message }: { message: ChatMessage }) {
  switch (message.type) {
    case 'audio':
      return <Audio />;
    case 'button':
      return <Button />;
    case 'contacts':
      return null;
    case 'document':
      return <Document />;
    case 'image':
      return <Image />;
    case 'interactive':
      return null;
    case 'location':
      return null;
    case 'rawTemplate':
      return <RawTemplate />;
    case 'reaction':
      return <Reaction emoji={message.emoji} />;
    case 'service-request':
      return <ServiceRequest />;
    case 'sticker':
      return <Sticker />;
    case 'text':
      return <Text text={message.text.body} />;
    case 'video':
      return <Video />;
    default:
      return null;
  }
}
