import { httpClientCIInstance } from '@/shared/services/api';
import { IrRegister } from '@/shared/types/interfaces';

type GetIrRegisterResponse = IrRegister;

export async function getIrRegister({
  vdId,
  signal,
}: {
  vdId: string;
  signal: AbortSignal;
}): Promise<GetIrRegisterResponse> {
  const res = await httpClientCIInstance(`v1/resellers/${vdId}/cadastro`, {
    method: 'GET',
    signal,
  });

  return res.json() as Promise<GetIrRegisterResponse>;
}
