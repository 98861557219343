import { Box, Flex, FloraTypography } from '@grupoboticario/flora-react';
import { CheckSingleIcon, TagPlusIcon, TagFullIcon } from '@grupoboticario/flora-react-icons';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { Tag } from '@/shared/types/interfaces';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

export type ChatTagType = { selected: boolean } & Tag;

type ChatTagsProps = {
  tags: ChatTagType[];
  handleSelected: (tag: ChatTagType) => void;
};

export function ChatTagsMenu({ tags, handleSelected }: ChatTagsProps) {
  return (
    <Menu
      arrow={true}
      align="center"
      direction="bottom"
      menuButton={
        <Flex
          className="btn-add-tag"
          css={{ marginLeft: 'auto' }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <TagPlusIcon size="20" color="$9" css={{ cursor: 'pointer' }} />
        </Flex>
      }
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        handleSelected(e.value);
        e.keepOpen = true;
      }}
      overflow="visible"
      position="initial"
      className="menu-contact-tags"
    >
      {tags.map(tag => (
        <MenuItem key={tag.id} value={tag}>
          <Box>
            <Flex direction="row" justify="center">
              <Flex direction="row" justify="left" css={{ minWidth: '20px' }}>
                {tag.selected && <CheckSingleIcon color="$9" size="15" />}
              </Flex>
              <Flex direction="row" justify="left" css={{ minWidth: '165px' }}>
                <FloraTypography fontSize="exceptionsAuxiliar" as="span" css={{ marginBottom: '3px' }}>
                  {tag.name}
                </FloraTypography>
              </Flex>
              <Flex direction="row" justify="end">
                <TagFullIcon size="15" css={{ color: `${tag.color}` }} />
              </Flex>
            </Flex>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
}
