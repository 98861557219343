import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { globalStyles } from '@/shared/styles/globalStyles';
import { useNotifications } from '@/shared/hooks';
import { AppContainer } from '@/shared/components';

import { queryClient } from './shared/services/api/queryClient';

export default function Root() {
  globalStyles();
  useNotifications();

  return (
    <QueryClientProvider client={queryClient}>
      <AppContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
