import { getResellerData } from '@/shared/services/api/instant-messenger/service/getResellerData';
import { useContactStore } from '@/shared/state';
import { useMutation, useQuery } from '@tanstack/react-query';
import { events } from '@/shared/services/events';
import { Reseller } from '../types/interfaces';

export const useFetchContact = ({ enabled, vdId }: { enabled: boolean; vdId: Reseller['vdId'] }) => {
  const { setContact } = useContactStore();

  return useQuery({
    queryKey: ['contact', vdId],
    queryFn: ({ signal }) => getResellerData({ vdId, signal }),
    enabled,
    select: data => {
      if (enabled && vdId) {
        setContact(data);
      }
      return data;
    },
  });
};

export const useFetchContactWithMutation = () => {
  const { setContact } = useContactStore();

  return useMutation({
    mutationFn: ({ vdId }: { vdId: Reseller['vdId'] }) => getResellerData({ vdId }),
    onSuccess: data => {
      setContact(data);
    },
    onError: (error: any) => {
      if (error?.response?.status === 404) {
        events.error({
          description: 'Revendedor não encontrado.',
        });
      }
    },
  });
};
