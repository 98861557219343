import { Audio } from '../MessageTypes/Audio';
import { Text } from '../MessageTypes/Text';
import { Document } from '../MessageTypes/Document';
import { Image } from '../MessageTypes/Image';
import { WhatsAppTemplate } from '../MessageTypes/WhatsAppTemplate';
import { Sticker } from '../MessageTypes/Sticker';
import { Video } from '../MessageTypes/Video';
import { Message } from '@/shared/services/api/instant-messenger/types';
import { isAMessageFromSelectedContact } from '@/shared/functions';

export function MessageTypesSwitcher({ message }: Readonly<{ message: Message }>) {
  switch (message.type) {
    case 'audio':
      return <Audio {...message.audio} />;
    case 'button':
      return <Text text={message.button.text} isAMessageFromSelectedContact={isAMessageFromSelectedContact(message)} />;
    // case 'contacts':
    //   return null;
    case 'document':
      return <Document {...message.document} isAMessageFromSelectedContact={isAMessageFromSelectedContact(message)} />;
    case 'image':
      return <Image {...message.image} />;
    // case 'interactive':
    //   return null;
    // case 'location':
    //   return null;
    case 'rawTemplate':
      return <WhatsAppTemplate rawTemplate={message.rawTemplate} />;
    case 'sticker':
      return <Sticker {...message.sticker} />;
    case 'text':
      return <Text text={message.text.body} isAMessageFromSelectedContact={isAMessageFromSelectedContact(message)} />;
    case 'video':
      return <Video {...message.video} />;
    default:
      return <div>Tipo de mensagem não suportado</div>;
  }
}
