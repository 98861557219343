import { DEFAULT_LOCALE } from '@/shared/locale';
import { checkFileSizeExceedsLimit } from './checkFileSizeExceedsLimit';
import { hasInvalidFileMimeType } from './hasInvalidFileMimeType';

const mimeTypeListFormatter = new Intl.ListFormat(DEFAULT_LOCALE, {
  style: 'short',
  type: 'disjunction',
});

function rawFileTypeList(allowedMimeTypeList: Array<string>) {
  return allowedMimeTypeList.map(mimeType => mimeType.split('/')[1]);
}

export function createFileValidationSchema({
  allowedMimeTypeList,
  maxFileSizeInMB,
}: {
  allowedMimeTypeList: Array<string>;
  maxFileSizeInMB: number;
}) {
  return [
    (file: File) => ({
      isValid: !hasInvalidFileMimeType({ file, allowedMimeTypeList }),
      error: `O formato do arquivo ${file.name} é inválido. Envie um arquivo ${mimeTypeListFormatter.format(rawFileTypeList(allowedMimeTypeList))}.`,
    }),
    (file: File) => ({
      isValid: !checkFileSizeExceedsLimit({ file, maxFileSizeInMB }),
      error: `O tamanho do arquivo ${file.name} não pode exceder ${maxFileSizeInMB}MB.`,
    }),
  ];
}
