import { Box, styled } from '@grupoboticario/flora-react';

export const ContactSearchForm = styled(Box, {
  position: 'relative',
  '& [data-flora=input-wrapper]': {
    minHeight: 'unset',
  },
  '& #searchResellerField-error-msg': {
    height: 'unset',
  },
}) as typeof Box;
