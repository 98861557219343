import { MessageDateTime } from '@/features/MessagesList';
import { formatCurrentCycle } from '@/shared/functions';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { deleteTagFromChat, putTagOnChat } from '@/shared/services/api/instant-messenger/service';
import { events } from '@/shared/services/events';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { useState } from 'react';
import { ActivityCycleTag } from '../ActivityCycleTag.styled';
import { ChatInfo } from '../ChatInfo.styled';
import { ChatItemWrapper } from '../ChatItemWrapper.styled';
import { ChatLastMessageDateTime } from '../ChatLastMessageDateTime.styled';
import { ChatTags } from '../ChatTags';
import { ChatTagType, ChatTagsMenu } from '../ChatTagsMenu';
import { ChatLastMessageExcerptSwitcher } from '../MessageExcerptTypes';
import { ChatItemProps } from './ChatItem';

type ChatItemResellerProps = ChatItemProps;

export function ChatItemReseller({ chat, selected, tabIndex, tags, onClick }: ChatItemResellerProps) {
  const [contactTags, setContactTags] = useState<Array<ChatTagType>>(() => {
    return tags.map(tag => {
      return {
        selected: chat?.tags?.some(t => t.tag.id === tag.id) || false,
        ...tag,
      };
    });
  });

  async function handleSelected(tag: ChatTagType) {
    try {
      if (tag.selected) {
        await deleteTagFromChat({ chatId: chat.id, tagId: tag.id });
      } else {
        await putTagOnChat({ chatId: chat.id, tagId: tag.id });
      }
      setContactTags(tags => tags.map(t => (t.id === tag.id ? { ...t, selected: !t.selected } : t)));
    } catch (error) {
      events.error({
        description: 'Ocorreu um erro ao atualizar o rótulo',
      });
      sendErrorToNewRelic(error);
    }
  }

  return (
    <ChatItemWrapper
      tabIndex={tabIndex}
      selected={selected}
      status={chat.status}
      data-testid={chat.contact.id}
      direction="column"
      onClick={onClick}
      accountType="reseller"
    >
      <Flex className="tags-list" direction="row" css={{ paddingLeft: '$8' }}>
        {contactTags
          .filter(tag => tag.selected)
          .map(tag => (
            <ChatTags key={tag.id} tag={tag} />
          ))}
      </Flex>
      <Flex direction="row" align="center" className="contact-basic-info">
        <ActivityCycleTag currentCycle={chat.contact.currentCycle}>
          {formatCurrentCycle(chat.contact.currentCycle)}
        </ActivityCycleTag>
        <ChatInfo as="span" fontSize="bodyLargeStandard" unread={chat.status === 'unread'} selected={selected}>
          <span className="chat-contact-name">{chat.contact.name.toLowerCase()} </span>
          <FloraTypography as="span" fontSize="bodySmallStandard" css={{ fontVariantNumeric: 'tabular-nums' }}>
            ({chat.contact.vdId})
          </FloraTypography>
        </ChatInfo>
        <ChatLastMessageDateTime>
          <MessageDateTime createdAt={chat.message.createdAt} />
        </ChatLastMessageDateTime>
      </Flex>
      <Flex
        css={{
          marginTop: '$2',
          paddingLeft: '33px',
          color: '#444',
          fontSize: '$auxiliar',
        }}
        align="center"
        direction="row"
        className="more-info"
      >
        <ChatLastMessageExcerptSwitcher message={chat.message} />
        <ChatTagsMenu tags={contactTags} handleSelected={handleSelected} />
      </Flex>
    </ChatItemWrapper>
  );
}
