import { useEffect } from 'react';
import { styled, Container } from '@grupoboticario/flora-react';
import { useFetchContact } from '../../hooks/useFetchContact';
import { useUrlParams } from '../../hooks/useUrlParams';
import { useFetchAccounts } from '../../hooks/useFetchAccounts';
import { useAccountStore, useContactStore } from '../../state';

import { ContactSearch } from '@/features/ContactSearch';
import { ChatList } from '@/features/ChatList';
import { MessagesList } from '@/features/MessagesList';

import { Notify } from '../Notifications';
import { ChatWrapper } from '../ChatWrapper';
import { ChatsListWrapper } from '../ChatsListWrapper';
import { ContactSearchWrapper } from '../ContactSearchWrapper';
import { MessagesListWrapper } from '../MessagesListWrapper';

const AppContainerWrapper = styled(Container, {
  maxWidth: '100%',
  minHeight: '100dvh',
  backgroundColor: '$backgroundPrimary',
  padding: 0,
}) as typeof Container;

export function AppContainer() {
  const { account } = useAccountStore();
  const { contact } = useContactStore();
  const urlParams = useUrlParams();
  const contactVdId = urlParams.get('contact');

  useFetchAccounts({ enabled: !account });
  const { data: dataContact, error: errorContact } = useFetchContact({
    vdId: contactVdId,
    enabled: Boolean(account?.id && contactVdId && contact?.vdId !== contactVdId),
  });

  useEffect(() => {
    /**
     * Espera receber dados ou erro para limpar o endereço da URL
     */
    if (dataContact || errorContact) {
      history.replaceState('', '', '/chat');
    }
  }, [dataContact, errorContact]);

  return (
    <AppContainerWrapper as="main">
      <Notify />
      <ChatWrapper>
        <ChatsListWrapper data-testid="left-column">
          <ContactSearchWrapper>
            <ContactSearch />
          </ContactSearchWrapper>
          <ChatList />
        </ChatsListWrapper>
        <MessagesListWrapper data-testid="right-column">
          <MessagesList />
        </MessagesListWrapper>
      </ChatWrapper>
      <div id="image-gallery"></div>
      <div id="image-upload-preview"></div>
    </AppContainerWrapper>
  );
}
