import { getIrCredits } from '@/shared/services/api/ci/services';
import { useQuery } from '@tanstack/react-query';

export const useIrCredits = ({ vdId }: { vdId: string }) => {
  return useQuery({
    queryKey: ['irCredits', vdId],
    queryFn: ({ signal }) => getIrCredits({ vdId, signal }),
    enabled: !!vdId,
    placeholderData: {
      availableCreditLimit: 0,
      totalCreditLimit: 0,
      creditLimitInvoiced: 0,
      creditTolerance: 0,
      creditLimitDue: 0,
    },
    staleTime: 12 * 60 * 60 * 1000, // 12 horas
    gcTime: 12 * 60 * 60 * 1000, // 12 horas
  });
};
