import { FC, memo } from 'react';
import DOMPurify from 'dompurify';
import { FloraTypography, FloraTypographyProps, styled } from '@grupoboticario/flora-react';

import { formatText } from '@/shared/functions';

interface TextMessageProps {
  text: string;
  isAMessageFromSelectedContact: boolean;
}

const StyledText = styled(FloraTypography, {
  margin: 0,
  textWrap: 'pretty',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  '& code': {
    color: 'inherit',
  },

  variants: {
    isAMessageFromSelectedContact: {
      false: {
        '& a': {
          color: '$backgroundPrimary',
        },
      },
    },
  },
}) as FC<
  FloraTypographyProps & { isAMessageFromSelectedContact: boolean; dangerouslySetInnerHTML?: { __html: string } }
>;

export const Text = memo(({ text, isAMessageFromSelectedContact }: TextMessageProps) => {
  const cleanText = DOMPurify.sanitize(formatText(text));

  return (
    <StyledText
      isAMessageFromSelectedContact={isAMessageFromSelectedContact}
      fontSize="bodyLargeShort"
      data-testid="text-message"
      dangerouslySetInnerHTML={{ __html: cleanText }}
    />
  );
});
