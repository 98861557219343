import { authService } from '@vdi/auth-service';
import ky, { BeforeRequestHook } from 'ky';

const beforeRequest: BeforeRequestHook = async (request: Request) => {
  const { token } = await authService.getTokenOrRedirect();
  request.headers.append('Authorization', `Bearer ${token}`);
};

export const httpClientInstance = ky.create({
  prefixUrl: `${process.env.API_URL}`,
  //credentials: 'include',
  hooks: { beforeRequest: [beforeRequest] },
});

export const httpClientCAInstance = ky.create({
  prefixUrl: `${process.env.API_BFF_CA_URL}`,
  hooks: { beforeRequest: [beforeRequest] },
});

export const httpClientCIInstance = ky.create({
  prefixUrl: `${process.env.API_BFF_CI_URL}`,
  hooks: { beforeRequest: [beforeRequest] },
});
