import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ContactCode } from './ContactCode.styled';
import { ContactInfoTitle } from './ContactInfoTitle.styled';
import { ContactInfoWrapper } from './ContactInfoWrapper.styled';
import { useContactStore } from '@/shared/state';
import { toCurrency } from '@/shared/functions';
import { useIrCredits } from '../hooks/useIrCredits';
import { useIrRegister } from '../hooks/useIrRegister';
import { useIrRisks } from '../hooks/useIrRisks';
import { ContactRisk } from './ContactRisk';

export function ContactInfoIr() {
  const { contact } = useContactStore();
  const { data: dataCredits } = useIrCredits({ vdId: contact.vdId });
  const { data: dataRegister } = useIrRegister({ vdId: contact.vdId });
  const { data: dataRisks } = useIrRisks({ cpf: dataRegister?.cpf });

  return (
    <ContactInfoWrapper direction="row" align="center" gapX="$6">
      <Flex direction="column">
        <ContactInfoTitle as="h3">Contato</ContactInfoTitle>
        <Flex direction="row">
          <FloraTypography
            as="span"
            fontSize="bodyLargeStandard"
            fontWeight="bold"
            css={{
              width: '120px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: '$actionableDefault',
            }}
            title={contact.name}
          >
            {contact.name}
          </FloraTypography>
        </Flex>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Código VD+</ContactInfoTitle>
        <ContactCode>{contact.vdId}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Crédito</ContactInfoTitle>
        <ContactCode>{toCurrency(dataCredits?.availableCreditLimit)}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">CS</ContactInfoTitle>
        <ContactCode>{dataRegister?.commercialStructure.parentStructure.parentStructureCode}</ContactCode>
      </Flex>
      <ContactRisk riskAnalysis={dataRisks?.riskAnalysis} />
    </ContactInfoWrapper>
  );
}
