import { useAccountStore, useChatStore } from '@/shared/state';
import { ContactInfoIr } from './components/ContactInfoIr';
import { ContactInfoReseller } from './components/ContactInfoReseller';

export function ContactInfo() {
  const { account } = useAccountStore();
  const { chat } = useChatStore();

  const contactAccount = chat ? chat.account : account;

  switch (contactAccount.type) {
    case 'ir':
      return <ContactInfoIr />;
    case 'reseller':
      return <ContactInfoReseller />;
  }
}
