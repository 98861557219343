import { getIrRisks } from '@/shared/services/api/ci/services';
import { useQuery } from '@tanstack/react-query';

export const useIrRisks = ({ cpf }: { cpf: string }) => {
  return useQuery({
    queryKey: ['irRisks', cpf],
    queryFn: ({ signal }) => getIrRisks({ cpf, signal }),
    enabled: !!cpf,
    placeholderData: {
      cpf: '',
      riskAnalysis: '',
      geraId: '',
    },
    staleTime: 12 * 60 * 60 * 1000, // 12 horas
    gcTime: 12 * 60 * 60 * 1000, // 12 horas
  });
};
