import { forwardRef, KeyboardEvent, ChangeEvent, Ref } from 'react';
import { Box } from '@grupoboticario/flora-react';

interface EditableInputProps {
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const EditableInput = forwardRef(({ onKeyDown, onInput }: EditableInputProps, ref: Ref<any>) => (
  <Box
    ref={ref}
    data-testid="send-message"
    role="textbox"
    aria-labelledby="Digite uma mensagem"
    contentEditable
    css={{
      borderRadius: '$medium',
      background: '$backgroundPrimary',
      cursor: 'text',
      minHeight: '44px',
      maxHeight: '100px',
      overflow: 'auto',
      padding: '$3',
      '&:focus': {
        outline: 'none',
      },
      '&:empty:before': {
        content: 'attr(data-placeholder)',
        color: '$nonInteractiveAuxiliar',
      },
    }}
    data-placeholder="Digite uma mensagem"
    onKeyDown={onKeyDown}
    onInput={onInput}
  />
));
