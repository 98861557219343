import { fetcher } from '@/shared/services/api/fetcher';
import { Chat } from '@/shared/types/interfaces';

type UpdateChatRequest = {
  status: 'read';
};

export async function updateChat({
  chatId,
  payload,
}: {
  chatId: Chat['id'];
  payload: UpdateChatRequest;
}): Promise<void> {
  await fetcher.patch(`v1/chats/${chatId}`, {
    json: payload,
  });
}
