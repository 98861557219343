import { styled, Flex } from '@grupoboticario/flora-react';

export const MessagesListWrapper = styled(Flex, {
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '$backgroundSecondary',
  borderLeft: '1px solid #d1d7db',

  // scrollbar CSS to Firefox
  scrollbarColor: 'rgba(11,20,26,.2) rgba(255,255,255,.1)',
  scrollbarWidth: 'thin',

  // scrollbar CSS to Webkit browsers
  '&::-webkit-scrollbar': {
    width: '4px',
    position: 'absolute',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(11,20,26,.2)',
  },
}) as typeof Flex;
