import { FC } from 'react';
import { styled, Flex, FlexProps } from '@grupoboticario/flora-react';

export const MessageWrapperStyled = styled(Flex, {
  borderRadius: '$medium',
  boxShadow: '$downClose',
  maxWidth: '65%',
  padding: '$3',
  position: 'relative',
  '&:hover .message-reply': {
    display: 'inline-flex',
  },
  variants: {
    isAMessageFromSelectedContact: {
      true: {
        backgroundColor: '$backgroundPrimary',
        color: '#444',
        justifySelf: 'start',
      },
      false: {
        backgroundColor: '$nonInteractiveEmphasis',
        color: '$backgroundPrimary',
        justifySelf: 'end',
      },
    },
  },
}) as FC<FlexProps & { isAMessageFromSelectedContact: boolean }>;
