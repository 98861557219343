import { ActivityCycleTag } from '@/features/ChatList/components/ActivityCycleTag.styled';
import { formatCurrentCycle, toCurrency } from '@/shared/functions';
import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ContactCode } from './ContactCode.styled';
import { ContactInfoTitle } from './ContactInfoTitle.styled';
import { ContactInfoWrapper } from './ContactInfoWrapper.styled';
import { useContactStore } from '@/shared/state';
import { useResellerCredits } from '../hooks/useResellerCredits';

export function ContactInfoReseller() {
  const { contact } = useContactStore();
  const { data: dataCredits } = useResellerCredits({ vdId: contact.vdId });

  return (
    <ContactInfoWrapper direction="row" align="center" gapX="$6">
      <Flex direction="column">
        <ContactInfoTitle as="h3">Contato</ContactInfoTitle>
        <Flex direction="row">
          <ActivityCycleTag currentCycle={contact.currentCycle}>
            {formatCurrentCycle(contact.currentCycle)}
          </ActivityCycleTag>
          <FloraTypography
            as="span"
            fontSize="bodyLargeStandard"
            fontWeight="bold"
            css={{
              width: '120px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: '$actionableDefault',
            }}
            title={contact.name}
          >
            {contact.name}
          </FloraTypography>
        </Flex>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Código VD+</ContactInfoTitle>
        <ContactCode>{contact.vdId}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Crédito liberado com tolerância</ContactInfoTitle>
        <ContactCode>{toCurrency(dataCredits?.availableCredit)}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Crédito total com tolerância</ContactInfoTitle>
        <ContactCode>{toCurrency(dataCredits?.totalCredit)}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Saldo CCR</ContactInfoTitle>
        <ContactCode>{toCurrency(dataCredits?.balanceCCR)}</ContactCode>
      </Flex>
    </ContactInfoWrapper>
  );
}
