import { fetcher } from '@/shared/services/api/fetcher';
import { GetChatsResponse } from '@/shared/types/interfaces';

export async function getChats(next: string | undefined): Promise<GetChatsResponse> {
  let uri = `v1/chats`;
  if (next) {
    uri += `?next=${next}`;
  }
  return fetcher.get<GetChatsResponse>(uri, {});
}
