import { PersonHeadsetIcon } from '@grupoboticario/flora-react-icons';

export function ServiceRequest() {
  return (
    <>
      <PersonHeadsetIcon css={{ marginRight: '4px' }} size={16} color="$nonInteractiveAuxiliar" /> Estou solicitando seu
      contato
    </>
  );
}
