import { useQuery } from '@tanstack/react-query';
import { getTags } from '@/shared/services/api/instant-messenger/service';

export function useFetchTagList({ enabled }: { enabled: boolean }) {
  return useQuery({
    queryKey: ['tags'],
    queryFn: ({ signal }) => getTags({ signal }),
    enabled,
    gcTime: 12 * 60 * 60 * 1000, // 12 horas
    staleTime: 12 * 60 * 60 * 1000, // 12 horas
  });
}
