import { FloraButton } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import { Message } from '@/shared/services/api/instant-messenger/types/InstantMessengerType';

type ButtonCancelReplyProps = {
  setMessageSelectedToReply: (value: Message | undefined) => void;
};

export function ButtonCancelReply({ setMessageSelectedToReply }: ButtonCancelReplyProps) {
  return (
    <FloraButton
      hierarchy="tertiary"
      has="iconOnly"
      icon={<CrossIcon />}
      aria-label="asd"
      onClick={() => setMessageSelectedToReply(undefined)}
      css={{
        '&:hover': { backgroundColor: 'transparent' },
        '& svg': { width: '24px', height: '24px' },
        padding: '11px',
      }}
    />
  );
}
