import { fetcher } from '@/shared/services/api/fetcher';
import { Account, GetTemplatesResponse, Template } from '@/shared/types/interfaces';

export async function getTemplates({
  accountId,
  signal,
}: {
  accountId: Account['id'];
  signal: AbortSignal;
}): Promise<GetTemplatesResponse> {
  const uri = `v1/accounts/${accountId}/templates`;
  return fetcher.get<GetTemplatesResponse>(uri, { signal });
}

export async function findTemplate({
  accountId,
  templateName,
  signal,
}: {
  accountId: Account['id'];
  templateName: string;
  signal: AbortSignal;
}): Promise<Template> {
  const uri = `v1/accounts/${accountId}/templates/${templateName}`;
  return fetcher.get<Template>(uri, { signal });
}
