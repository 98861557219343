import { styled } from '@grupoboticario/flora';

export const ImageZoomedWrapper = styled('div', {
  maxWidth: '100%',
  height: 'auto',
  display: 'flex',
  placeContent: 'center',
  placeItems: 'center',
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  zIndex: '20',
  backgroundColor: 'white',
  padding: '1rem',
  flexFlow: 'column wrap',
  gap: '$4',

  '& img': {
    '--x': '50%',
    '--y': '50%',
    '--zoom': '1',

    '&:hover': {
      '--zoom': '2',
      cursor: 'crosshair',
    },

    maxHeight: 'calc(100dvh - 6rem)',
    backgroundColor: '$backgroundSecondary',
    cursor: 'zoom-out',
    borderRadius: '7px',
    transform: 'scale(var(--zoom))',
    transformOrigin: 'var(--x) var(--y)',
    clipPath:
      'inset(calc((1 - 1/var(--zoom)) * (var(--y))) calc((1 - 1/var(--zoom)) * (100% - var(--x))) calc((1 - 1/var(--zoom)) * (100% - var(--y))) calc((1 - 1/var(--zoom)) * (var(--x))))',
  },
});
