import { fetcher } from '@/shared/services/api/fetcher';
import { MessengerResponse } from '@/shared/services/api/instant-messenger/types';
import { Account, Reseller } from '@/shared/types/interfaces';

export interface GetMessagesRequest {
  contactId: Reseller['id'];
  next?: string;
}

export async function getMessages({
  accountId,
  contactId,
  next,
}: {
  accountId: Account['id'];
  contactId: Reseller['id'];
  next?: string;
}): Promise<MessengerResponse> {
  let url = `v1/accounts/${accountId}/messages/${contactId}`;
  if (next) {
    url += `?next=${next}`;
  }
  return fetcher.get<MessengerResponse>(url, {});
}
