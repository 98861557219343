import { httpClientInstance } from '@/shared/services/api';
import { Account } from '@/shared/types/interfaces';

export interface GetMediaResponse {
  url: string;
}

export async function getMediaUrl({
  accountId,
  mediaId,
}: {
  accountId: Account['id'];
  mediaId: string;
}): Promise<GetMediaResponse> {
  const res = await httpClientInstance(`v1/accounts/${accountId}/medias/${mediaId}`, {
    method: 'GET',
    redirect: 'manual',
  });

  return res.json() as Promise<GetMediaResponse>;
}
