import { httpClientCIInstance } from '@/shared/services/api';
import { IrRisks } from '@/shared/types/interfaces';

type GetIrRisksResponse = IrRisks;

export async function getIrRisks({ cpf, signal }: { cpf: string; signal: AbortSignal }): Promise<GetIrRisksResponse> {
  const res = await httpClientCIInstance('v1/risks', {
    method: 'POST',
    signal,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      cpf,
    }),
  });

  return res.json() as Promise<GetIrRisksResponse>;
}
