/**
 * Converte o conteúdo WhatsApp em html
 * @param {String} content - Texto em formatação WhatsApp
 * @returns {String} Texto html
 */
export function richTextIntoHTML(content: string): string {
  const htmlFormat = [
    { symbol: '*', tag: 'strong' },
    { symbol: '_', tag: 'em' },
    { symbol: '~', tag: 'del' },
    { symbol: '```', tag: 'code' },
  ];

  for (const { symbol, tag } of htmlFormat) {
    const regex = new RegExp(`(\\${symbol}([^${symbol}]*)\\${symbol})`, 'gm');
    const match = content.match(regex);
    if (!match) {
      continue;
    }

    for (const m of match) {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `${i > 0 ? '</' : '<'}${tag}>`);
      }
      content = content.replace(m, formatted);
    }
  }

  // Altera todas quebras de linha por <br> para simular um "enter"
  content = content.replace(/\n/g, '<br>');
  return content;
}
