import { Tooltip } from '@grupoboticario/flora-react';
import { TagFullIcon } from '@grupoboticario/flora-react-icons';
import { ChatTagType } from '.';

type ChatTagsProps = {
  tag: ChatTagType;
};

export function ChatTags({ tag }: ChatTagsProps) {
  return (
    <Tooltip align="start" direction="top" showArrow size="standard" text={tag.name}>
      <TagFullIcon css={{ color: `${tag.color}` }} size="15" />
    </Tooltip>
  );
}
