import { Box } from '@grupoboticario/flora-react';
import type { ImageItem } from './types';

type SelectedProps = {
  imagePreviewList: Array<ImageItem>;
  selectedImageIndex: number;
};

export function Selected({ imagePreviewList, selectedImageIndex }: SelectedProps) {
  return (
    <Box
      css={{
        position: 'relative',
        flex: '1 1 auto',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {imagePreviewList.map(({ url, name }, index) => (
        <Box
          key={`${name}-${index}`}
          css={{
            display: 'none',
            ...(selectedImageIndex === index && {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
          }}
        >
          <img
            src={url}
            alt={`Image ${index}`}
            style={{
              maxWidth: '90%',
              maxHeight: 'calc(100vh - 160px)',
              objectFit: 'scale-down',
              boxShadow: '0 3px 12px rgba(10,20,26,.16)',
            }}
          />
        </Box>
      ))}
    </Box>
  );
}
