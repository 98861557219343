import { memo, useEffect, useState } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { Box, Flex, FloraTypography, styled } from '@grupoboticario/flora-react';
import { DocumentSingleIcon, TrayArrowDownIcon } from '@grupoboticario/flora-react-icons';
import { getMediaUrl } from '@/shared/services/api/instant-messenger/service/getMedia';
import { useAccountStore } from '@/shared/state';

type DocumentMessageProps = {
  id: string;
  link?: string;
  caption?: string;
  filename?: string;
  isAMessageFromSelectedContact?: boolean;
};

const DocumentDownloadArea = styled(Box, {
  borderRadius: '4px',
  padding: '16px',
  variants: {
    isAMessageFromSelectedContact: {
      true: {
        backgroundColor: 'rgba(0,0,0,.06)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.1)',
        },
      },
      false: {
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#eee',
        },
      },
    },
  },
});

export const Document = memo((props: DocumentMessageProps) => {
  const [src, setSrc] = useState<string | undefined>(() => ('link' in props ? props.link : undefined));
  const prevId = usePrevious(props.id);
  const { account } = useAccountStore();

  async function fetchDocument(mediaId: string) {
    const res = await getMediaUrl({ accountId: account.id, mediaId });
    setSrc(res.url);
  }

  useEffect(
    function fetchDocumentWhenNoLinkProvided() {
      if (!src) {
        fetchDocument(props.id);
      }
    },
    [src],
  );

  useEffect(
    function fetchDocumentWhenIdChanges() {
      if (props.id !== prevId && prevId !== undefined) {
        fetchDocument(props.id);
      }
    },
    [prevId, props.id],
  );

  return (
    <>
      <DocumentDownloadArea
        as="a"
        href={props.link || src}
        download={props.link || src}
        target="_blank"
        css={{ transition: 'all 200ms ease-in-out' }}
        isAMessageFromSelectedContact={props.isAMessageFromSelectedContact}
      >
        <Flex direction="row" align="center">
          <DocumentSingleIcon
            css={{
              minWidth: '44px',
              minHeight: '44px',
              opacity: '0.5',
              backgroundColor: '#ccc',
              borderRadius: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '1px',
              color: '#444',
            }}
          />
          <FloraTypography
            fontSize="bodySmallStandard"
            as="span"
            css={{ margin: '0 0 0 16px', display: 'inline-flex' }}
          >
            {props.filename}{' '}
            <TrayArrowDownIcon
              css={{
                marginLeft: '8px',
              }}
            />
          </FloraTypography>
        </Flex>
      </DocumentDownloadArea>
      <FloraTypography as="span" fontSize="bodySmallStandard">
        {props.caption}
      </FloraTypography>
    </>
  );
});
