import { Box, Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ChatBubbleIcon } from '@grupoboticario/flora-react-icons';

export function BlankState() {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      css={{ minHeight: '100dvh', borderLeft: '1px solid #e9edef' }}
    >
      <ChatBubbleIcon color="$nonInteractiveAltAuxiliar" size="128" />
      <Box css={{ marginTop: '$4' }}>
        <FloraTypography as="span" fontSize="bodyLargeStandard" css={{ color: '$nonInteractiveAuxiliar' }}>
          Para iniciar um atendimento, faça uma busca ou selecione uma conversa ao lado.
        </FloraTypography>
      </Box>
    </Flex>
  );
}
