import { Account, Reseller } from '@/shared/types/interfaces';
import { fetcher } from '../../fetcher';
import { Message } from '../types';

type SendReactionRequest = {
  reaction: string;
};

export async function sendReaction({
  accountId,
  contactId,
  messageId,
  payload,
}: {
  accountId: Account['id'];
  contactId: Reseller['id'];
  messageId: Message['id'];
  payload: SendReactionRequest;
}): Promise<void> {
  await fetcher.patch(`v1/accounts/${accountId}/messages/${contactId}/${messageId}`, {
    json: payload,
  });
}
