import { FC } from 'react';
import { styled, FloraTypography, FloraTypographyProps } from '@grupoboticario/flora-react';

export const ChatInfo = styled(FloraTypography, {
  color: '$nonInteractiveEmphasis',
  textTransform: 'capitalize',
  variants: {
    selected: {
      true: {
        color: '$actionableDefault',
        fontWeight: '$bold',
      },
    },
    unread: {
      true: {
        fontWeight: '$bold',
      },
    },
  },
}) as FC<FloraTypographyProps & { unread: boolean; selected: boolean }>;
