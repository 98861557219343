import { FloraButton } from '@grupoboticario/flora-react';
import {
  TemplateButtonsComponent,
  TemplateButtonsParams,
} from '@/shared/services/api/instant-messenger/types/InstantMessengerType';
import { SquareArrowRightUpIcon, PhoneIcon } from '@grupoboticario/flora-react-icons';
import { useMemo } from 'react';

const handleButtonClick = (url: TemplateButtonsComponent['buttons'][number]['url']) => {
  if (url) {
    window.open(url, '_blank');
  }
  return;
};

const handleIcon = (type: TemplateButtonsComponent['buttons'][number]['type']) => {
  switch (type.toLowerCase()) {
    case 'phone_number':
      return <PhoneIcon />;
    case 'url':
      return <SquareArrowRightUpIcon />;
    default:
      return null;
  }
};

export function TemplateButtons({
  component,
  paramsList,
}: {
  component: TemplateButtonsComponent;
  paramsList: TemplateButtonsParams['parameters'] | undefined;
}) {
  const buttons = useMemo(() => {
    const updatedButtons = [...component.buttons];
    paramsList?.forEach(param => {
      updatedButtons[param.index].url = updatedButtons[param.index].url.replace('{{1}}', param.content);
    });
    return updatedButtons;
  }, [component.buttons, paramsList]);

  return (
    <>
      {buttons.map(({ type, url, phone_number, text }, index) => (
        <FloraButton
          hierarchy="primary"
          styleSemantic="neutral"
          surfaceColor="dark"
          key={index}
          has="iconLeft"
          icon={handleIcon(type)}
          onClick={() => handleButtonClick(url)}
          title={phone_number || url || undefined}
          css={{ marginTop: '1rem' }}
        >
          {text}
        </FloraButton>
      ))}
    </>
  );
}
