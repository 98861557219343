import { memo } from 'react';
import { Box, FloraButton } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import type { ImageItem } from './types';

type ThumbnailListProps = {
  imagePreviewList: Array<ImageItem>;
  isUploadingMedia: boolean;
  removeImagePreviewFromList: (name: string) => void;
  selectedImageIndex: number;
  setSelectedImageIndex: (index: number) => void;
};

export const ThumbnailList = memo(function ThumbnailList({
  imagePreviewList,
  setSelectedImageIndex,
  selectedImageIndex,
  removeImagePreviewFromList,
  isUploadingMedia,
}: ThumbnailListProps) {
  return (
    <Box
      css={{
        display: 'flex',
        padding: '16px 0',
        gap: '8px',
        overflowX: 'auto',
        flex: '1 0 0',
      }}
    >
      {imagePreviewList.map(({ url, name }, index) => (
        <Box
          key={`${name}-${index}`}
          onClick={() => setSelectedImageIndex(index)}
          css={{
            position: 'relative',
            border: '2px solid #ddd',
            borderRadius: '6px',
            transition: 'opacity 1s ease-in',
            '&::after': {
              position: 'absolute',
              top: '0',
              right: '0',
              width: '100%',
              height: '100%',
              content: '""',
              background:
                'linear-gradient(10deg, transparent 0%, transparent 45%, rgba(10,20,26, .18) 75%, rgba(10,20,26, .4) 100%)',
              opacity: '0',
              transition: 'opacity 100ms ease-out',
              borderRadius: '5px',
            },
            '&:hover::after': {
              opacity: 1,
              transition: 'opacity 100ms ease-in',
            },
            '&:hover': {
              cursor: 'pointer',
            },
            ...(selectedImageIndex === index && { borderColor: '#264fec' }),
          }}
        >
          <img
            src={url}
            alt={`Thumbnail ${index}`}
            style={{
              width: '64px',
              height: '64px',
              objectFit: 'cover',
              borderRadius: '5px',
            }}
          />
          <FloraButton
            hidden={isUploadingMedia}
            size="small"
            hierarchy="tertiary"
            has="iconOnly"
            icon={<CrossIcon />}
            onClick={() => removeImagePreviewFromList(name)}
            css={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 200,
              color: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
});
