import { Image2Icon } from '@grupoboticario/flora-react-icons';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import MediaUploaderButton from './MediaUploaderButton';
import { ImagePreviewer } from './ImagePreviewer';

type ImageUploaderButtonProps = {
  sendMessageWithReply: (payload: SendMessageData) => Promise<void>;
  setIsUploadingMedia: (isUploading: boolean) => void;
  setMediaUploaderMenuVisibility: (isOpen: boolean) => void;
  isUploadingMedia: boolean;
};

export function ImageUploaderButton({
  sendMessageWithReply,
  setIsUploadingMedia,
  isUploadingMedia,
  setMediaUploaderMenuVisibility,
}: ImageUploaderButtonProps) {
  return (
    <>
      <ImagePreviewer
        sendMessageWithReply={sendMessageWithReply}
        setIsUploadingMedia={setIsUploadingMedia}
        isUploadingMedia={isUploadingMedia}
        setMediaUploaderMenuVisibility={setMediaUploaderMenuVisibility}
      />
      <MediaUploaderButton
        IconComponent={<Image2Icon />}
        htmlFor="image-input-uploader"
        ariaLabel="Clique para selecionar um arquivo de imagem para enviar"
      />
    </>
  );
}
