import { Flex } from '@grupoboticario/flora-react';

export function MessageReplyIcon({ onClick, isChatSessionOpen }) {
  if (!isChatSessionOpen) return null;
  return (
    <Flex
      justify="center"
      css={{
        display: 'none',
        position: 'absolute',
        right: '0',
        top: '0',
        padding: '4px',
        width: '32px',
        background: 'radial-gradient(at top right, rgba(0,0,0,0.5) 50%, rgba(255, 255, 255, 0) 80%)',
        color: 'white',
        borderRadius: '0 8px',
        cursor: 'pointer',
      }}
      onClick={onClick}
      className="message-reply"
    >
      <svg
        viewBox="0 0 32 32"
        width="12"
        height="12"
        fill="none"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      >
        <path d="M10 6 L3 14 10 22 M3 14 L18 14 C26 14 30 18 30 26" />
      </svg>
    </Flex>
  );
}
