import mitt from 'mitt';
import { MessageType } from '@/shared/components/Notifications';
import { GENERIC_ERROR } from '@/shared/components/Notifications/messages';
import {
  Message,
  MessageReactionReceived,
  MessageReactionSent,
  MessageReceived,
  MessageSent,
  MessageStatusReceived,
  ServiceRequestReceived,
} from '@/shared/services/api/instant-messenger/types';

export type EventType = {
  MESSAGE_RECEIVED: MessageReceived;
  MESSAGE_REACTION_RECEIVED: MessageReactionReceived;
  MESSAGE_REACTION_SENT: MessageReactionSent;
  MESSAGE_SELECTED: Message;
  MESSAGE_SENT: MessageSent;
  MESSAGE_STATUS_RECEIVED: MessageStatusReceived;
  NOTIFICATION: MessageType;
  SERVICE_REQUEST_RECEIVED: ServiceRequestReceived;
};

const mittImplementation = mitt<EventType>();

const onError = (options?: Partial<Omit<MessageType, 'kind'>>): void =>
  mittImplementation.emit('NOTIFICATION', {
    ...GENERIC_ERROR,
    kind: 'error',
    ...options,
  });

const events = {
  ...mittImplementation,
  error: onError,
};

export { events };
