import { Flex } from '@grupoboticario/flora-react';
import { ContactCode } from './ContactCode.styled';
import { ContactInfoTitle } from './ContactInfoTitle.styled';

type ContactRiskProps = {
  riskAnalysis: string;
};

const riskTranslation = new Map([
  ['lowRisk', 'Baixo'],
  ['mediumRisk', 'Médio'],
  ['highRisk', 'Alto'],
  ['notProcessed', 'Não processado'],
]);

export function ContactRisk({ riskAnalysis }: ContactRiskProps) {
  function getStatus(riskAnalysis: string) {
    if (!riskTranslation.has(riskAnalysis)) {
      return '--';
    }
    return riskTranslation.get(riskAnalysis);
  }

  return (
    <Flex direction="column">
      <ContactInfoTitle as="h3">Risco</ContactInfoTitle>
      <ContactCode>{getStatus(riskAnalysis)}</ContactCode>
    </Flex>
  );
}
