import { getIrRegister } from '@/shared/services/api/ci/services';
import { useQuery } from '@tanstack/react-query';

export const useIrRegister = ({ vdId }: { vdId: string }) => {
  return useQuery({
    queryKey: ['irRegister', vdId],
    queryFn: ({ signal }) => getIrRegister({ vdId, signal }),
    enabled: !!vdId,
    placeholderData: {
      cpf: '',
      commercialStructure: {
        name: '',
        parentStructure: {
          name: '',
          parentStructureCode: '--',
        },
      },
    },
    staleTime: 12 * 60 * 60 * 1000, // 12 horas
    gcTime: 12 * 60 * 60 * 1000, // 12 horas
  });
};
