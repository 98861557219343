import { useCallback, useEffect } from 'react';

export function useFixedScroll({ el, hasMore, fetchData }) {
  const getData = useCallback(fetchData, [hasMore]);

  useEffect(() => {
    const hasScroll = el.current ? el.current.scrollHeight > el.current.clientHeight : false;
    if (!hasScroll && hasMore !== undefined) {
      setTimeout(() => {
        getData();
      }, 100);
    }
  }, [hasMore, fetchData]);
}
