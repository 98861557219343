import { useState } from 'react';
import { createPortal } from 'react-dom';
import { FloraButton, Image } from '@grupoboticario/flora-react';
import { CrossCircleIcon, PaperAirplaneIcon } from '@grupoboticario/flora-react-icons';
import useKey from 'react-use/lib/useKey';

import { ImageZoomedWrapper } from '@/features/MessagesList/components/MessageTypes/Image/ImageZoomed';
import { events } from '@/shared/services/events';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { useAccountStore } from '@/shared/state';

type ImagePreviewProps = {
  imageObjectURL: string;
  imageRawFile: File;
  sendMessageWithReply: (payload: SendMessageData) => Promise<void>;
  resetImagePreview: () => void;
};

export function ImagePreview({
  imageObjectURL,
  imageRawFile,
  sendMessageWithReply,
  resetImagePreview,
}: ImagePreviewProps) {
  const [sendingPasteImage, setSendingPasteImage] = useState(false);
  useKey('Escape', resetImagePreview);
  const { account } = useAccountStore();

  async function sendImageFromPaste() {
    try {
      setSendingPasteImage(true);
      const { id } = await uploadMedia({ accountId: account.id, file: imageRawFile });
      await sendMessageWithReply({
        provider: 'whatsapp',
        type: 'image',
        image: {
          id,
        },
      });
      events.emit('NOTIFICATION', {
        description: `Imagem enviada com sucesso`,
        kind: 'success',
      });
      resetImagePreview();
    } catch (error) {
      return events.error({ description: 'Erro ao enviar mensagem do tipo imagem, tente novamente.' });
    } finally {
      setSendingPasteImage(false);
    }
  }

  return createPortal(
    <ImageZoomedWrapper>
      <Image src={imageObjectURL} alt="Imagem colada" />
      <FloraButton
        onClick={sendImageFromPaste}
        disabled={sendingPasteImage}
        icon={<PaperAirplaneIcon />}
        has="iconRight"
      >
        Enviar imagem
      </FloraButton>
      <FloraButton
        onClick={resetImagePreview}
        hierarchy="tertiary"
        has="iconOnly"
        aria-label="Fechar"
        css={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          color: '$nonInteractiveAuxiliar',
          padding: '5px',
          '&:hover': { backgroundColor: 'transparent' },
          '& svg': { width: '44px', height: '44px' },
        }}
        icon={<CrossCircleIcon />}
      />
    </ImageZoomedWrapper>,
    document.querySelector('#image-gallery')!,
  );
}
