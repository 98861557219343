import { FloraButton } from '@grupoboticario/flora-react';

import { PaperAirplaneIcon } from '@grupoboticario/flora-react-icons';

export const MessageSenderButton = (): JSX.Element => {
  return (
    <FloraButton
      hierarchy="tertiary"
      has="iconOnly"
      type="submit"
      aria-label="Enviar mensagem"
      role="button"
      icon={<PaperAirplaneIcon size="25" />}
      css={{
        color: '$backgroundPrimary',
        transition: 'transform 200ms ease-in-out',
        '&:hover': { transform: 'translateX(4px)', backgroundColor: 'transparent' },
        '& svg': {
          width: '24px',
          height: '24px',
        },
        padding: '11px',
      }}
    />
  );
};
